
export default {
  name: 'MainNavbar',
  computed: {
    items() {
      let baseItems = []

      if (!this.$auth.loggedIn) {
        return baseItems
      }
      if (this.$unleash.isEnabled('dashboard.launch.v2')) {
        if (this.$user.isSuperadmin) {
          baseItems = [
            ...baseItems,
            {
              title: 'Admin',
              action: 'mdi-desktop-mac-dashboard',
              active: false,
              items: [
                { title: 'Organizations Management', to: '/organizations' },
                { title: 'Systems Directory', to: '/systems' },
                { title: 'Users', to: '/users' },
              ],
            },
          ]
        }
        baseItems = [
          ...baseItems,
          {
            title: 'Organizations Directory',
            action: 'mdi-office-building',
            active: true,
            click: () => {
              window.location.href = `${this.$config.AD_FRONTEND_DIRECTORY_EXTERNAL_BASE_URL}/organizations`
            },
          },
          {
            title: 'Users Directory',
            action: 'mdi-account-box',
            active: false,
            click: () => {
              window.location.href = `${this.$config.AD_FRONTEND_DIRECTORY_EXTERNAL_BASE_URL}/users`
            },
          },
        ]
        if (
          this.$user.is(['administrator', 'liaison', 'user-manager', 'support'])
        ) {
          baseItems = [
            ...baseItems,
            {
              title: 'My office(s)',
              action: 'mdi-cog-box',
              active: false,
              click: () => {
                this.$router.push('/dashboard/organizations')
              },
            },
          ]
        }
        return baseItems
      }

      if (this.$auth.loggedIn) {
        baseItems = [
          ...baseItems,
          {
            title: 'Organizations',
            action: 'mdi-office-building',
            active: true,
            items: [{ title: 'Organization Directory', to: '/organizations' }],
          },
          {
            title: 'Users',
            action: 'mdi-account-box',
            active: false,
            items: [{ title: 'User Directory', to: '/users' }],
          },
        ]
      }
      if (this.$user.isSuperadmin) {
        baseItems = [
          ...baseItems,
          {
            title: 'Systems Directory',
            action: 'mdi-desktop-mac-dashboard',
            active: false,
            click: () => {
              this.$router.push('/systems')
            },
          },
        ]
      }

      return baseItems
    },
  },
}
